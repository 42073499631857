import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import "./ContactInformation.css"

import Marker from "../../images/placeholder.svg";
import Email from "../../images/email.svg";
import Tele from "../../images/phone-call.svg";
import fbWhite from "../../images/facebook-logo-blue.svg";
import instaWhite from "../../images/instagram-logo-blue.svg";


function ContactInformation(props) {
    const data = props.data;

    console.log(data)

    return (
        <div className="py-12 ci-tab px-8">
            <h2 className="ci-h2">{data.informationTitle}</h2>
            <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell" style={{ width: "32px" }}>
                    <img src={Marker} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={data.address.addressGoogleLink} className="ci-links table-cell align-middle">{data.address.addressLabel}</a>
            </div>
            <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell">
                    <img src={Tele} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={`tel:${data.telephoneNumber}`} className="ci-links table-cell align-middle">{data.telephoneNumber}</a>
            </div>
            <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell">
                    <img src={Email} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={`mailto:${data.supportEmail}`} className="ci-links table-cell align-middle">{data.supportEmail}</a>
            </div>
            <div className="pb-4 w-full text-left justify-start pt-8">
                <div className="flex">
                    <a href="https://www.facebook.com/pluspapageorgiou" target="_blank" rel="noopener" className="ci-social"><img src={fbWhite} alt="facebook-icon" style={{ width: "20px" }} /></a>
                    <a href="https://www.instagram.com/plus_medlab/" target="_blank" rel="noopener" className="ml-2 ci-social"><img src={instaWhite} alt="instagram-icon" style={{ width: "20px" }} /></a>
                </div>
            </div>

            <h2 className="ci-h2 mt-8">{data.hoursTitle}</h2>

            {
                props.language === "EN"
                    ?
                    <div className="pb-4 w-full text-left justify-start pt-4 flex-wrap" >
                        <p className="ci-links align-middle w-full block" style={{ paddingLeft: "0px" }}>Monday - Friday: 7:30 - 15:30</p>
                        <p className="ci-links align-middle w-full block" style={{ paddingLeft: "0px" }}>Saturday: 9:00 - 13:00</p>
                    </div>
                    :
                    <div className="pb-4 w-full text-left justify-start pt-4 flex-wrap" >
                        <p className="ci-links align-middle w-full block" style={{ paddingLeft: "0px" }}>Δευτέρα - Παρασκευή: 7:30 - 15:30</p>
                        <p className="ci-links align-middle w-full block" style={{ paddingLeft: "0px" }}>Σάββατο: 9:00 - 13:00</p>
                    </div>

            }

        </div>
    )
}

export default ContactInformation;